import React, { Suspense, useEffect, useRef, useState } from "react";
import { Canvas, useFrame } from "@react-three/fiber";
import { Html, Sky } from "@react-three/drei";
import Wall from "./Wall";
import Effects from "./Effects";
import { useKeyboardInput } from "./useKeyboardInput";
import { useVariable } from "./useVariable";
import mvid from "./resources/mvid.gif";
import ascii from "./resources/ascii.gif";
import bookclub from "./resources/bookclub.avif";

function LightModeWall({ lightMode }) {
  const skyRef = useRef();

  useFrame((canvasState, delta) => {
    if (skyRef.current && skyRef.current.visible !== lightMode) {
      skyRef.current.visible = lightMode;
    }
  });
  return (
    <Sky
      azimuth={1}
      inclination={0.6}
      distance={1000}
      ref={skyRef}
      visible={false}
    />
  );
}

function About() {
  const [hoverElement, setHoverElement] = useState(null);
  return (
    <>
      <Html
        className="about"
        style={{
          transform: "translateY(-12%)",
        }}
      >
        <div className="explainer-wrap">
          <h2 className="paragraph">About</h2>
          <p className="paragraph">
            I previously founded a company called{" "}
            <a
              target="_blank"
              href="https://www.investmentnews.com/two-startups-merge-into-1-2-billion-digital-family-office-243242"
              rel="noreferrer"
            >
              Compound
            </a>
            , a $1.2B AUM tech-enabled asset management platform.
            <br />
            <br /> Before that, I worked as a software engineer at Blend, Course
            Hero, and other startups.
            <br />
            <br />
            I studied computer science at Washington University in St. Louis and
            Hong Kong University of Science and Technology (HKUST).
            <br />
            <br />I have built{" "}
            <a
              href="https://www.producthunt.com/posts/bookclub"
              target="_blank"
              rel="noreferrer"
              onMouseEnter={() => setHoverElement("bookclub")}
              onMouseLeave={() => setHoverElement(null)}
            >
              a
            </a>{" "}
            <a
              href="https://share.cleanshot.com/6GT03x"
              target="_blank"
              rel="noreferrer"
              onMouseEnter={() => setHoverElement("mvid")}
              onMouseLeave={() => setHoverElement(null)}
            >
              lot of
            </a>{" "}
            <a
              href="https://share.cleanshot.com/RMF79YRz"
              target="_blank"
              rel="noreferrer"
              onMouseEnter={() => setHoverElement("ascii")}
              onMouseLeave={() => setHoverElement(null)}
            >
              side projects
            </a>{" "}
            along the way (many of which are inactive now — vestiges can be
            found on my{" "}
            <a
              href="https://github.com/jischein"
              target="_blank"
              rel="noreferrer"
            >
              Github
            </a>
            ).
            <br />
            <br />
            --
            <br />
            <br />
            You can email me at{" "}
            <a href="mailto:scheinjacob@gmail.com">scheinjacob@gmail.com</a>.
          </p>
        </div>
        <img
          style={{
            top: "0vh",
            left: "10vw",
            position: "absolute",
            width: "450px",
            height: "auto",
            display: hoverElement === "mvid" ? "block" : "none",
          }}
          src={mvid}
          alt="windows"
        />
        <img
          style={{
            bottom: "35vh",
            right: "55vw",
            position: "absolute",
            width: "350px",
            height: "auto",
            display: hoverElement === "ascii" ? "block" : "none",
          }}
          src={ascii}
          alt="ascii"
        />
        <img
          style={{
            bottom: "10vh",
            right: "55vw",
            position: "absolute",
            width: "350px",
            height: "auto",
            display: hoverElement === "bookclub" ? "block" : "none",
          }}
          src={bookclub}
          alt="bookclub"
        />
      </Html>
    </>
  );
}

function ContentWrapper() {
  // const sound = useRef();

  const lightModeRef = useRef(false);
  const lastSetRef = useRef(new Date());
  const pressed = useKeyboardInput(["l", " "]);
  const [aboutActive, setAboutActive] = useState(false);
  const [orbHover, setOrbHover] = useState(false);

  useEffect(() => {
    // check if about in URL, if it is, setAboutActive(true)
    const url = window.location.href;
    const isAbout = url.includes("about");
    if (isAbout) {
      setAboutActive(true);
    }
  }, []);

  const input = useVariable(pressed);
  useFrame((canvasState, delta) => {
    const { l } = input.current;
    const curTime = new Date();
    const timeDiff = curTime - lastSetRef.current;
    if (l && timeDiff > 100) {
      const curLightMode = !!lightModeRef.current;
      const nextVal = !curLightMode;
      lastSetRef.current = curTime;
      lightModeRef.current = nextVal;
    }
  });

  // Listen for escape key press

  useEffect(() => {
    const handleKeyDown = (e) => {
      switch (e.key) {
        case "Escape":
          setAboutActive(false);
          setOrbHover(false);
          break;
        case "a":
          setAboutActive(true);
          break;
        case "h":
          setAboutActive(false);
          setOrbHover(false);
          break;
        default:
          break;
      }
    };
    window.addEventListener("keydown", handleKeyDown);
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, []);

  const MainContent = () => (
    <Html
      style={{
        transform: "translateY(-12%)",
      }}
      className={lightModeRef.current ? "light-mode" : "dark-mode"}
    >
      <p className="paragraph title-line">Hi, my name is Jacob Schein.</p>
    </Html>
  );

  return (
    <>
      <LightModeWall lightMode={lightModeRef.current} />
      <ambientLight />
      <pointLight position={[10, 10, 10]} />
      <Suspense fallback={null}>
        <Wall
          active={aboutActive}
          setActive={setAboutActive}
          orbHover={orbHover}
          setOrbHover={setOrbHover}
          lightMode={lightModeRef.current}
        />
        {aboutActive ? <About /> : <MainContent />}
        <Effects lightMode={lightModeRef.current} isAbout={aboutActive} />
        <Html
          as="nav"
          className={
            lightModeRef.current && !aboutActive ? "light-mode" : "dark-mode"
          }
        >
          <ul role="tablist">
            <li role="tab" aria-selected={!aboutActive ? true : false}>
              <button
                style={{
                  textDecoration: aboutActive ? "none" : "underline",
                }}
                onClick={(e) => {
                  e.preventDefault();
                  setOrbHover(false);
                  // window.location.hash = "/";
                  // set URL to '/'
                  setAboutActive(false);
                }}
              >
                Home
              </button>
            </li>
            <li role="tab" aria-selected={aboutActive ? true : false}>
              <button
                style={{
                  textDecoration: aboutActive ? "underline" : "none",
                }}
                onClick={(e) => {
                  e.preventDefault();
                  // window.location.hash = "/about";
                  setAboutActive(true);
                }}
              >
                About
              </button>
            </li>
          </ul>
        </Html>
      </Suspense>
    </>
  );
}

export default function Main() {
  return (
    <>
      <Canvas camera={{ position: [0, 0, 5] }} dpr={[1, 2]}>
        <ContentWrapper />
      </Canvas>
    </>
  );
}

import * as THREE from "three";
import React, { useRef } from "react";
import { useFrame, useLoader, useThree } from "@react-three/fiber";
import bg from "./resources/tt3.jpeg";
import windowsBg from "./resources/bliss.jpeg";
import "./materials/Shiny";
import { useControls } from "leva";
import { Spherical, Vector3 } from "three";
import { config, useSpring, animated } from "@react-spring/three";

export default function Wall(props) {
  const material = useRef();
  const mesh = useRef();
  const tRef = useRef();
  const [texture] = useLoader(THREE.TextureLoader, [
    props.lightMode ? windowsBg : bg,
  ]);
  texture.wrapS = texture.wrapT = THREE.RepeatWrapping;
  const { camera, viewport } = useThree();
  useFrame(({ clock, mouse }, delta) => {
    let aguleRadians = (Math.PI / 14) * clock.getElapsedTime();
    let sphericalPosition = new Spherical(14, Math.PI / 2, aguleRadians);
    const positionContainer1 = new Vector3(0, 0, 0).setFromSpherical(
      sphericalPosition
    );

    material.current.time += delta + Math.sin(clock.elapsedTime / 100) / 1000;
    mesh.current.rotation.x = Math.sin(clock.elapsedTime) * (Math.PI / 20);
    // camera.rotation.y += rotation;

    const mouseX = (mouse.x * viewport.width) / 2;
    const mouseY = (mouse.y * viewport.height) / 2;
    // mesh.current.position.set(mouseX, mouseY, 0);
    const currentX = mesh.current.position.x;
    const currentY = mesh.current.position.y;
    const mouseXWithinOnePxOfCurrentX = Math.abs(mouseX - currentX) < 1;
    const mouseXGreaterThanCurrentX = mouseX > currentX;
    const incrementX = mouseXGreaterThanCurrentX ? true : false;
    const mouseXWithinOnePxOfCurrentY = Math.abs(mouseY - currentY) < 1;
    const mouseYGreaterThanCurrentY = mouseY > currentY;
    const incrementY = mouseYGreaterThanCurrentY ? true : false;
    let newX = currentX;
    let newY = currentY;
    if (!mouseXWithinOnePxOfCurrentX) {
      const amountToUpdate = incrementX ? 0.05 : -0.05;
      newX += amountToUpdate;
    }
    if (!mouseXWithinOnePxOfCurrentY) {
      const amountToUpdate = incrementY ? 0.05 : -0.05;
      newY += amountToUpdate;
    }
  });

  const scaleToUse = (() => {
    if (props.active) {
      return 12;
    }
    return props.orbHover ? 1.25 : 1;
  })();

  const NORMAL_POS = [0, 0.35, 0];
  const scale = useSpring({
    scale: scaleToUse,
    config: {
      duration: !props.active ? 300 : 500,
      easing: (t) => t * (2 - t),
    },
  });
  return (
    <>
      <animated.mesh
        {...props}
        onPointerEnter={() => {
          if (props.active) {
            return;
          }
          props.setOrbHover(true);
          document.body.style.cursor = "pointer";
        }}
        onPointerLeave={() => {
          props.setOrbHover(false);
          document.body.style.cursor = "auto";
        }}
        onClick={() => {
          if (!props.active) {
            props.setActive(true);
            document.body.style.cursor = "auto";
          }
        }}
        ref={mesh}
        position={NORMAL_POS}
        scale={scale.scale}
      >
        <sphereGeometry args={[0.65, 40, 40]} />
        <shinyMaterial ref={material} noiseTexture={texture} />
      </animated.mesh>
    </>
  );
}

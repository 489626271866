import * as THREE from "three";
import React, { useRef, useMemo, useEffect } from "react";
import { extend, useThree, useFrame } from "@react-three/fiber";
import { EffectComposer } from "three/examples/jsm/postprocessing/EffectComposer";
import { ShaderPass } from "three/examples/jsm/postprocessing/ShaderPass";
import { RenderPass } from "three/examples/jsm/postprocessing/RenderPass";
import { AfterimagePass } from "three/examples/jsm/postprocessing/AfterimagePass";
import { GlitchPass } from "three/examples/jsm/postprocessing/GlitchPass";
import { UnrealBloomPass } from "three/examples/jsm/postprocessing/UnrealBloomPass";

import { NoiseShader } from "./shaders/NoiseShader.js";
import { SpaceShader } from "./shaders/SpaceShader";

extend({
  EffectComposer,
  ShaderPass,
  RenderPass,
  UnrealBloomPass,
  AfterimagePass,
  GlitchPass,
});

export default function Effects({ lightMode, isAbout }) {
  const composer = useRef();
  const { scene, gl, size, camera } = useThree();
  const aspect = useMemo(() => new THREE.Vector2(512, 512), []);
  const noisePass = useRef();
  const spacePass = useRef();
  const analyser = useRef();
  const afterImageRef = useRef();
  useEffect(
    () => void composer.current.setSize(size.width, size.height),
    [size]
  );
  useFrame(({ clock }) => {
    let freq = 0;
    if (analyser.current) {
      const data = analyser.current.getAverageFrequency();
      freq = data;
    }
    noisePass.current.uniforms.time.value = clock.elapsedTime;
    spacePass.current.uniforms.time.value = clock.elapsedTime;
    spacePass.current.uniforms.freq.value = freq;
    composer.current.render();
    const curAfterImage = afterImageRef.current.uniforms;
    const noDamp = curAfterImage.damp.value === 0;
    if (lightMode && !noDamp) {
      curAfterImage.damp.value = 0;
    }
    if (isAbout && noDamp) {
      curAfterImage.damp.value = 0.9;
    }
    if (!isAbout && !noDamp) {
      curAfterImage.damp.value = 0;
    }
  }, 1);

  useEffect(() => {
    if (afterImageRef && afterImageRef.current.uniforms) {
      const curAfterImage = afterImageRef.current.uniforms;
      if (curAfterImage.damp.value > 0.75) {
        curAfterImage.damp.value = 0;
      }
    }
  }, [afterImageRef]);

  return (
    <effectComposer ref={composer} args={[gl]}>
      <renderPass attachArray="passes" scene={scene} camera={camera} />
      <afterimagePass
        attachArray="passes"
        scene={scene}
        camera={camera}
        ref={afterImageRef}
      />
      {/* <unrealBloomPass attachArray="passes" args={[aspect, 0.2, 0.5, 0]} /> */}
      {/* <glitchPass
        attachArray="passes"
        args={[0.1]}
        scene={scene}
        camera={camera}
      /> */}
      {/* <shaderPass attachArray="passes" args={[CrtShader]} scene={scene} camera={camera} /> */}

      {/* <shaderPass attachArray="passes" args={[TiltShiftShader]} scene={scene} camera={camera} /> */}
      <shaderPass
        ref={noisePass}
        attachArray="passes"
        args={[NoiseShader]}
        scene={scene}
        camera={camera}
      />
      <shaderPass
        ref={spacePass}
        attachArray="passes"
        args={[SpaceShader]}
        scene={scene}
        camera={camera}
      />
    </effectComposer>
  );
}

// const blendPass = new THREE.ShaderPass(THREE.BlendShader, "tDiffuse1");
// blendPass.uniforms["tDiffuse2"].value = savePass.renderTarget.texture;
// blendPass.uniforms["mixRatio"].value = 0.8;
